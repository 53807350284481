import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Add = () => {
  const [workOrder, setWorkOrder] = useState({
    work_order_id: "",
    last_name: "",
    status: "",
    status_percentage: null,
    inspection_grade: null,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setWorkOrder((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const returnToHome = () => {
    navigate("/");
  };

  const handleAddWorkOrder = async () => {
    try {
      axios.post("https://skipperbuds.tools:8843/workOrders", workOrder).then((res) => {
        axios.post("https://skipperbuds.tools:8843/inspectionChecklist", workOrder).then((res) => {
        navigate("/");
      });
    });
      
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="form">
      <h1>Add New Work Order</h1>
      <div>
        <label>Work Order ID: </label>
        <input
          type="text"
          placeholder="work_order_id"
          onChange={handleChange}
          name="work_order_id"
        ></input>
      </div>
      <div>
        <label>Last Name: </label>
        <input
          type="text"
          placeholder="last_name"
          onChange={handleChange}
          name="last_name"
        ></input>
      </div>
      <div>
        <label>Status: </label>
        <input
          type="text"
          placeholder="status"
          onChange={handleChange}
          name="status"
        ></input>
      </div>

      <div>
        <label>Status %: </label>
        <input
          type="number"
          placeholder="status_percentage"
          onChange={handleChange}
          name="status_percentage"
        ></input>
      </div>
      <div>
        <label>Inspection Grade: </label>
        <input
          type="number"
          placeholder="inspection_grade"
          onChange={handleChange}
          name="inspection_grade"
        ></input>
      </div>

      <button onClick={handleAddWorkOrder}>Add Work Order</button>
      <button onClick={returnToHome}>Back</button>
    </div>
  );
};

export default Add;
