import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Update = () => {
  const { work_order_id } = useParams();

  const [workOrder, setWorkOrder] = useState([]);

  useEffect(() => {
    const fetchCurrentWorkOrderData = async () => {
      try {
        axios
          .get("https://skipperbuds.tools:8843/workOrders/update/" + work_order_id)
          .then((res) => {
            setWorkOrder(res.data[0]);
          });
      } catch (err) {
        console.log(err);
      }
    };

    fetchCurrentWorkOrderData();
  }, [work_order_id]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setWorkOrder((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const returnToHome = () => {
    navigate("/");
  };

  const handleEditWorkOrder = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        "https://skipperbuds.tools:8843/workOrders/update/" + work_order_id,
        workOrder
      );
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="form">
      <h1>Update Work Order {work_order_id}</h1>
      <div>
        <label>Work Order ID: </label>
        <input
          type="text"
          placeholder={work_order_id}
          name="work_order_id"
          disabled
        ></input>
      </div>
      <div>
        <label>Last Name: </label>
        <input type="text" placeholder="last_name" name="last_name" value={workOrder.last_name} onChange={handleChange}></input>
      </div>
      <div>
        <label>Status: </label>
        <input type="text" placeholder="status" name="status" value={workOrder.status} onChange={handleChange}></input>
      </div>

      <div>
        <label>Status %: </label>
        <input
          type="number"
          placeholder="status_percentage"
          name="status_percentage"
          value={workOrder.status_percentage} onChange={handleChange}
        ></input>
      </div>
      <div>
        <label>Inspection Grade: </label>
        <input
          type="number"
          placeholder="inspection_grade"
          name="inspection_grade"
          value={workOrder.inspection_grade} onChange={handleChange}
        ></input>
      </div>

      <button onClick={handleEditWorkOrder}>Save Work Order Changes</button>
      <button onClick={returnToHome}>Back</button>
    </div>
  );
};

export default Update;
