import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const InspectionChecklist = () => {
  const { work_order_id } = useParams();
  const navigate = useNavigate();

  const [inspectionChecklist, setInspectionChecklist] = useState([]);

  const returnToHome = () => {
    navigate("/");
  };

  const handleSave = () => {
    try {
      axios
          .put("https://skipperbuds.tools:8843/inspectionChecklist/" + work_order_id, inspectionChecklist)
          .then((res) => {
            navigate("/");
          });
    } catch (err) {
      console.log(err);
    }
  };

  
  function handleValueChange(e) {
    setInspectionChecklist((prev) => ({ ...prev, [e.target.name]: e.target.value}));
  }

  const testbutton = () => {
    alert(inspectionChecklist);
  }

  useEffect(() => {
    
    const fetchInspectionChecklistData = async () => {
      try {
        axios
          .get("https://skipperbuds.tools:8843/inspectionChecklist/" + work_order_id)
          .then((res) => {
            console.log(res.data[0]);
            setInspectionChecklist(res.data[0]);
          })
      } catch (err) {
        console.log(err);
      }
    };

    fetchInspectionChecklistData();
  }, [work_order_id]);


  return (
    <div>
      <h1>Inspection Checklist</h1>
      <h2 style={{ borderBottom: "groove", paddingBottom: "5px" }}>Work Order ID: {work_order_id}</h2>
      <div style={{ borderBottom: "groove", paddingBottom: "5px" }}>
          <p>Customer:</p>
          <input
            name="customer"
            style={{ width: "80%"}}
            placeholder="Customer"
            onChange={handleValueChange}
            value={inspectionChecklist.customer}
          ></input>
        </div>
        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Battery-Condition, terminals</p>
          <input
            type="radio"
            name="battery"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.battery === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="battery"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.battery === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="battery"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.battery === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.battery === 'bad' && (
            <input
              name="battery_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Battery Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.battery_comments}
            ></input>
          )}
          </div>
        </div>
        
        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Horn</p>
          <input
            type="radio"
            name="horn"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.horn === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="horn"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.horn === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="horn"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.horn === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.horn === 'bad' && (
            <input
              name="horn_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Horn Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.horn_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Bilge Blower</p>
          <input
            type="radio"
            name="bilgeBlower"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgeBlower === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="bilgeBlower"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgeBlower === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="bilgeBlower"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgeBlower === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.bilgeBlower === 'bad' && (
            <input
              name="bilgeBlower_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Bilge Blower Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.bilgeBlower_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Bilge Pump/Sump Pump</p>
          <input
            type="radio"
            name="bilgePumpSumpPump"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgePumpSumpPump === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="bilgePumpSumpPump"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgePumpSumpPump === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="bilgePumpSumpPump"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bilgePumpSumpPump === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.bilgePumpSumpPump === 'bad' && (
            <input
              name="bilgePumpSumpPump_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Bilge Pump/Sump Pump Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.bilgePumpSumpPump_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Navigation Lights</p>
          <input
            type="radio"
            name="navLights"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.navLights === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="navLights"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.navLights === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="navLights"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.navLights === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.navLights === 'bad' && (
            <input
              name="navLights_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Navigation Lights Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.navLights_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Shift & Throttle</p>
          <input
            type="radio"
            name="shiftAndThrottle"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.shiftAndThrottle === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="shiftAndThrottle"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.shiftAndThrottle === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="shiftAndThrottle"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.shiftAndThrottle === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.shiftAndThrottle === 'bad' && (
            <input
              name="shiftAndThrottle_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Shift & Throttle Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.shiftAndThrottle_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Guages</p>
          <input
            type="radio"
            name="guages"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.guages === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="guages"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.guages === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="guages"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.guages === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.guages === 'bad' && (
            <input
              name="guages_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Guages Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.guages_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Windshield Wiper(s)</p>
          <input
            type="radio"
            name="windshieldWipers"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.windshieldWipers === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="windshieldWipers"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.windshieldWipers === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="windshieldWipers"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.windshieldWipers === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.windshieldWipers === 'bad' && (
            <input
              name="windshieldWipers_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Windshield Wiper(s) Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.windshieldWipers_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Fresh Water Pump</p>
          <input
            type="radio"
            name="freshWaterPump"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.freshWaterPump === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="freshWaterPump"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.freshWaterPump === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="freshWaterPump"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.freshWaterPump === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.freshWaterPump === 'bad' && (
            <input
              name="freshWaterPump_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Fresh Water Pump Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.freshWaterPump_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Depth Finder</p>
          <input
            type="radio"
            name="depthFinder"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.depthFinder === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="depthFinder"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.depthFinder === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="depthFinder"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.depthFinder === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.depthFinder === 'bad' && (
            <input
              name="depthFinder_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Depth Finder Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.depthFinder_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Misc. Electronics</p>
          <input
            type="radio"
            name="miscElectronics"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.miscElectronics === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="miscElectronics"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.miscElectronics === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="miscElectronics"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.miscElectronics === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.miscElectronics === 'bad' && (
            <input
              name="miscElectronics_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Misc. Electronic Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.miscElectronics_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Interior Lights</p>
          <input
            type="radio"
            name="interiorLights"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorLights === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="interiorLights"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorLights === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="interiorLights"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorLights === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.interiorLights === 'bad' && (
            <input
              name="interiorLights_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Interior Lights Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.interiorLights_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Latches/Knobs</p>
          <input
            type="radio"
            name="latchesKnobs"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.latchesKnobs === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="latchesKnobs"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.latchesKnobs === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="latchesKnobs"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.latchesKnobs === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.latchesKnobs === 'bad' && (
            <input
              name="latchesKnobs_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Latches/Knobs Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.latchesKnobs_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Interior - Carpet, Vinyl, Floor</p>
          <input
            type="radio"
            name="interiorCarpetVinylFloor"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorCarpetVinylFloor === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="interiorCarpetVinylFloor"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorCarpetVinylFloor === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="interiorCarpetVinylFloor"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.interiorCarpetVinylFloor === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.interiorCarpetVinylFloor === 'bad' && (
            <input
              name="interiorCarpetVinylFloor_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Interior - Carpet, Vinyl, Floor Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.interiorCarpetVinylFloor_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Canvas - Repairs, Snaps, Support Poles</p>
          <input
            type="radio"
            name="canvasRepairsSnapsSupportPipes"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.canvasRepairsSnapsSupportPipes === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="canvasRepairsSnapsSupportPipes"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.canvasRepairsSnapsSupportPipes === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="canvasRepairsSnapsSupportPipes"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.canvasRepairsSnapsSupportPipes === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.canvasRepairsSnapsSupportPipes === 'bad' && (
            <input
              name="canvasRepairsSnapsSupportPipes_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Canvas - Repairs, Snaps, Support Poles Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.canvasRepairsSnapsSupportPipes_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Tape Stripe/Decals</p>
          <input
            type="radio"
            name="tapeStripeDecals"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.tapeStripeDecals === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="tapeStripeDecals"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.tapeStripeDecals === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="tapeStripeDecals"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.tapeStripeDecals === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.tapeStripeDecals === 'bad' && (
            <input
              name="tapeStripeDecals_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Tape Stripe/Decals Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.tapeStripeDecals_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Bottom Paint Condition</p>
          <input
            type="radio"
            name="bottomPaintCondition"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bottomPaintCondition === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="bottomPaintCondition"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bottomPaintCondition === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="bottomPaintCondition"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bottomPaintCondition === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.bottomPaintCondition === 'bad' && (
            <input
              name="bottomPaintCondition_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Bottom Paint Condition Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.bottomPaintCondition_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Gel Coat/Fiberglass Damage</p>
          <input
            type="radio"
            name="gelCoatViberglassDamage"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gelCoatViberglassDamage === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="gelCoatViberglassDamage"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gelCoatViberglassDamage === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="gelCoatViberglassDamage"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gelCoatViberglassDamage === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.gelCoatViberglassDamage === 'bad' && (
            <input
              name="gelCoatViberglassDamage_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Gel Coat/Fiberglass Damage Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.gelCoatViberglassDamage_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Engine Oil Conditions/Levels</p>
          <input
            type="radio"
            name="engineOilConditionLevels"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineOilConditionLevels === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="engineOilConditionLevels"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineOilConditionLevels === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="engineOilConditionLevels"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineOilConditionLevels === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.engineOilConditionLevels === 'bad' && (
            <input
              name="engineOilConditionLevels_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Engine Oil Conditions/Levels Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.engineOilConditionLevels_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Engine Ignition Systems - Rough Running, <br/>Spark Plugs, Distributor Cap, etc.</p>
          <input
            type="radio"
            name="engineIgnitionSystem"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineIgnitionSystem === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="engineIgnitionSystem"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineIgnitionSystem === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="engineIgnitionSystem"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.engineIgnitionSystem === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.engineIgnitionSystem === 'bad' && (
            <input
              name="engineIgnitionSystem_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Engine Ignition Systems Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.engineIgnitionSystem_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Props</p>
          <input
            type="radio"
            name="props"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.props === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="props"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.props === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="props"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.props === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.props === 'bad' && (
            <input
              name="props_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Props Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.props_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Belts/Hoses</p>
          <input
            type="radio"
            name="beltsAndHoses"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.beltsAndHoses === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="beltsAndHoses"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.beltsAndHoses === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="beltsAndHoses"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.beltsAndHoses === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.beltsAndHoses === 'bad' && (
            <input
              name="beltsAndHoses_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Belts/Hoses Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.beltsAndHoses_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Flame Arrestor</p>
          <input
            type="radio"
            name="flameArrestor"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.flameArrestor === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="flameArrestor"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.flameArrestor === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="flameArrestor"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.flameArrestor === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.flameArrestor === 'bad' && (
            <input
              name="flameArrestor_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Flame Arrestor Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.flameArrestor_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Fluids - Leaks/Levels</p>
          <input
            type="radio"
            name="fluidsLeaksLevels"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.fluidsLeaksLevels === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="fluidsLeaksLevels"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.fluidsLeaksLevels === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="fluidsLeaksLevels"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.fluidsLeaksLevels === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.fluidsLeaksLevels === 'bad' && (
            <input
              name="fluidsLeaksLevels_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Fluids - Leaks/Levels Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.fluidsLeaksLevels_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Motor Mount Bolts - Tightness</p>
          <input
            type="radio"
            name="motorMountsBoltsTightness"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.motorMountsBoltsTightness === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="motorMountsBoltsTightness"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.motorMountsBoltsTightness === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="motorMountsBoltsTightness"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.motorMountsBoltsTightness === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.motorMountsBoltsTightness === 'bad' && (
            <input
              name="motorMountsBoltsTightness_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Motor Mount Bolts - Tightness Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.motorMountsBoltsTightness_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Buff/Wax</p>
          <input
            type="radio"
            name="buffWax"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.buffWax === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="buffWax"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.buffWax === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="buffWax"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.buffWax === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.buffWax === 'bad' && (
            <input
              name="buffWax_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Buff/Wax Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.buffWax_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Boat Registration</p>
          <input
            type="radio"
            name="boatRegistration"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.boatRegistration === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="boatRegistration"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.boatRegistration === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="boatRegistration"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.boatRegistration === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.boatRegistration === 'bad' && (
            <input
              name="boatRegistration_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Boat Registration Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.boatRegistration_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Bellows</p>
          <input
            type="radio"
            name="bellows"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bellows === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="bellows"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bellows === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="bellows"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bellows === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.bellows === 'bad' && (
            <input
              name="bellows_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Bellows Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.bellows_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Gimbal Ring</p>
          <input
            type="radio"
            name="gimbalRing"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalRing === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="gimbalRing"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalRing === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="gimbalRing"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalRing === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.gimbalRing === 'bad' && (
            <input
              name="gimbalRing_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Gimbal Ring Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.gimbalRing_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Sterndrive Condition - Skeg, Cavitation Plate, <br/> Corrosion, Anodes, etc.</p>
          <input
            type="radio"
            name="sterndriveCondition"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.sterndriveCondition === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="sterndriveCondition"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.sterndriveCondition === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="sterndriveCondition"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.sterndriveCondition === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.sterndriveCondition === 'bad' && (
            <input
              name="sterndriveCondition_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Sterndrive Condition Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.sterndriveCondition_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Trim Sender/Limit</p>
          <input
            type="radio"
            name="trimSenderLimit"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trimSenderLimit === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="trimSenderLimit"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trimSenderLimit === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="trimSenderLimit"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trimSenderLimit === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.trimSenderLimit === 'bad' && (
            <input
              name="trimSenderLimit_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Trim Sender/Limit Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.trimSenderLimit_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Gimbal Bearing/U-Joint - Listen for Knocking</p>
          <input
            type="radio"
            name="gimbalBearing"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalBearing === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="gimbalBearing"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalBearing === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="gimbalBearing"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.gimbalBearing === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.gimbalBearing === 'bad' && (
            <input
              name="gimbalBearing_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Gimbal Bearing/U-Joint Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.gimbalBearing_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Trailer Lights, Frame, Fenders, Tires</p>
          <input
            type="radio"
            name="trailerLightsFrameFendersTires"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trailerLightsFrameFendersTires === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="trailerLightsFrameFendersTires"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trailerLightsFrameFendersTires === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="trailerLightsFrameFendersTires"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.trailerLightsFrameFendersTires === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.trailerLightsFrameFendersTires === 'bad' && (
            <input
              name="trailerLightsFrameFendersTires_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Trailer Lights, Frame, Fenders, Tires Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.trailerLightsFrameFendersTires_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Winch/Strap, Jackstands</p>
          <input
            type="radio"
            name="winchStrapJackstands"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.winchStrapJackstands === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="winchStrapJackstands"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.winchStrapJackstands === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="winchStrapJackstands"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.winchStrapJackstands === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.winchStrapJackstands === 'bad' && (
            <input
              name="winchStrapJackstands_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Winch/Strap, Jackstands Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.winchStrapJackstands_comments}
            ></input>
          )}
          </div>
        </div>

        <div
          className="radioGroup"
          style={{ borderBottom: "groove", paddingBottom: "5px" }}
        >
          <p>Bunk/Rollers</p>
          <input
            type="radio"
            name="bunkRollers"
            value="n_a"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bunkRollers === 'n_a'}
          />
          <label htmlFor="n_a">N/A</label>

          <input
            type="radio"
            name="bunkRollers"
            value="ok"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bunkRollers === 'ok'}
          />
          <label htmlFor="ok">OK</label>

          <input
            type="radio"
            name="bunkRollers"
            value="bad"
            id="n_a"
            onChange={handleValueChange}
            checked={inspectionChecklist.bunkRollers === 'bad'}
          />
          <label htmlFor="bad">Bad</label>
          <div>
          {inspectionChecklist.bunkRollers === 'bad' && (
            <input
              name="bunkRollers_comments"
              style={{ width: "80%", padding: "5px", margin: "5px" }}
              placeholder="Bunk/Rollers Comments"
              onChange={handleValueChange}
              value={inspectionChecklist.bunkRollers_comments}
            ></input>
          )}
          </div>
        </div>

        <div style={{ borderBottom: "groove", paddingBottom: "5px" }}>
          <p>Tech Comments:</p>
          <input 
          type="textArea"
          style={{ width: "80%", height: "4rem", padding: "5px", margin: "5px" }}
          name="tech_comments"
          placeholder="Tech Comments"
          onChange={handleValueChange}
          value={inspectionChecklist.tech_comments}
          ></input>
        </div>

        <div style={{ borderBottom: "groove", paddingBottom: "5px" }}>
          <p>Tech:</p>
          <input
            name="tech_nm"
            style={{ width: "80%"}}
            placeholder="Tech"
            onChange={handleValueChange}
            value={inspectionChecklist.tech_nm}
          ></input>
        </div>

        <div style={{ borderBottom: "groove", paddingBottom: "5px" }}>
          <p>Created Date: {new Date(inspectionChecklist.created_dt).toDateString()}</p>
        </div>

        <div style={{ borderBottom: "groove", paddingBottom: "5px" }}>
          <p>Last Updated Date: {new Date(inspectionChecklist.last_updt_dt).toDateString()}</p>
        </div>






      <button onClick={returnToHome}>Back to Home</button>
      <button onClick={handleSave}>Save</button>
      <button onClick={testbutton}>Test</button>

    </div>
  );
};

export default InspectionChecklist;
