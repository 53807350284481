import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "editButton",
  headerName: "",
  sortable: false,
  flex: .5,
  renderCell: (params) => <Link to={'/update/' + params.row.work_order_id}>Edit</Link>
},
  {
    field: "work_order_id",
    headerName: "Work Order ID",
    flex: 1
  },
  { field: "last_name", headerName: "Last Name", flex: 1 },
  { field: "inspectionChecklist",
  headerName: "",
  sortable: false,
  flex: 1,
  renderCell: (params) => <Link to={'/inspectionChecklist/' + params.row.work_order_id}>Inspection Checklist</Link>
  },

  // { field: "status", headerName: "Status", flex: 1 },
  // {
  //   field: "status_percentage",
  //   headerName: "Status Percentage",
  //   flex: 0.5,
  //   type: "number",
  // },
  // {
  //   field: "inspection_grade",
  //   headerName: "Inspection Grade",
  //   flex: 0.5,
  //   type: "number",
  // },
];

const WorkOrders = () => {
  const [workOrders, setWorkOrders] = useState([]);

  useEffect(() => {
    
    const fetchAllWorkOrders = async () => {
      try {
        const res = await axios.get("https://skipperbuds.tools:8843/workOrders");
        setWorkOrders(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllWorkOrders();
  }, []);

  function getRowId(row) {
    return row.work_order_id;
  }

  return (
    <div>
      <h1>Work Orders</h1>
      <div style={{ height: "100%", width: "100%" }}>
        <DataGrid
          getRowId={getRowId}
          rows={workOrders}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          disableColumnMenu
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [],
              },
            }
          }}
        />
      </div>
      <button>
        <Link to={"/add"}>Add New Work Order</Link>
      </button>
    </div>
  );
};

export default WorkOrders;
