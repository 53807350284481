import { BrowserRouter, Routes, Route } from "react-router-dom";
import WorkOrders from "./pages/WorkOrders";
import Add from "./pages/Add";
import Update from "./pages/Update";
import InspectionChecklist from "./pages/InspectionChecklist";
import "./styles.css"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WorkOrders />} />
          <Route path="/add" element={<Add />} />
          <Route path="/update/:work_order_id" element={<Update />} />
          <Route path="/inspectionChecklist/:work_order_id" element={<InspectionChecklist/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
